import React from "react";
import Header from "../components/header";
import Layout from "../components/layout";

export default function Home() {
  return (
    <Layout>
      <Header headerText={"Home"} />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pretium
        felis lectus, at volutpat dolor egestas et. In imperdiet ullamcorper
        dignissim. Phasellus quis velit nec diam varius scelerisque eu in
        sapien. Sed hendrerit posuere magna, sed convallis odio ultrices at.
        Fusce eget enim eget nibh cursus elementum sed vel quam. Etiam iaculis
        pretium laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et
        ultrices posuere cubilia curae; Integer non elit quis ligula volutpat
        elementum. Ut malesuada lobortis orci in ullamcorper. Aliquam dignissim
        sem vel velit elementum, cursus interdum lorem ultrices. Ut eget nibh et
        mauris luctus iaculis.{" "}
      </p>
      <p>
        Vivamus condimentum ac metus eget efficitur. Vivamus placerat est
        ultrices ligula pulvinar egestas. Nulla in nisl sapien. Aenean vitae
        rutrum neque, a ullamcorper elit. Morbi pharetra dignissim quam, et
        hendrerit ligula dapibus vitae. Nulla vitae elit quis tellus semper
        interdum quis vel felis. Curabitur non eros orci.{" "}
      </p>
      <p>
        Nam lobortis est ut purus blandit, sit amet efficitur elit semper. Donec
        pulvinar cursus congue. Quisque luctus est eu arcu hendrerit bibendum.
        Quisque quis odio dolor. Donec rhoncus ipsum diam, ut convallis mauris
        scelerisque non. Cras rutrum et lectus nec dignissim. Donec ut nulla
        porttitor, finibus ligula vel, rutrum nunc. Fusce bibendum tellus et
        odio placerat maximus. Aliquam dictum accumsan lectus.{" "}
      </p>
    </Layout>
  );
}
